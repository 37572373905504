import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetDiagnosticsCommandParam, GetLogCommandParam } from 'types/ocpp/OCPP';
import { ChargingStation, ChargingStationConfigurationMode } from '../../types/ChargingStation';
import { ActionResponse, ChargingStationDataResult } from '../../types/DataResult';
import { RESTServerRoute } from '../../types/Server';
import { buildUrl } from '../../utils/url/url.util';
import { Command } from './charging-station.dto';

@Injectable()
export class ChargingStationApiService {
  public constructor(private httpClient: HttpClient) {}

  public getChargingStations(params: {
    Issuer?: boolean;
    WithSiteArea?: boolean;
    WithSite?: boolean;
    WithUser?: boolean;
    Limit?: number;
    SortFields?: string;
  }): Observable<ChargingStationDataResult> {
    return this.httpClient.get<ChargingStationDataResult>(RESTServerRoute.REST_CHARGING_STATIONS, {
      params,
    });
  }

  public updateChargingStation(chargingStation: ChargingStation): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_UPDATE_PARAMETERS, {
      id: chargingStation.id,
    });
    return this.httpClient.put<ActionResponse>(url, chargingStation);
  }

  public switchChargingStationToConfigMode(
    id: string,
    mode: ChargingStationConfigurationMode,
  ): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_CONFIGURATION_MODE, {
      id: id,
      mode: mode,
    });
    return this.httpClient.put<ActionResponse>(url, {});
  }

  public getDiagnostics(chargerId: string, body: GetDiagnosticsCommandParam): Observable<ActionResponse> {
    const args = {
      args: body,
    };
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_GET_DIAGNOSTICS, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, args);
  }

  public getLog(chargerId: string, body: GetLogCommandParam): Observable<ActionResponse> {
    const args = {
      args: body,
    };

    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_GET_LOG, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, args);
  }

  public executeCommand(
    command: Command,
    chargerId: string,
    body?: GetDiagnosticsCommandParam | GetLogCommandParam,
  ): Observable<ActionResponse> {
    switch (command) {
      case Command.GET_LOG:
        body['remoteLocation'] = { log: body['remoteLocation'] };
        return this.getLog(chargerId, body as GetLogCommandParam);
      case Command.GET_DIAGNOSTICS:
        return this.getDiagnostics(chargerId, body as GetDiagnosticsCommandParam);
      default:
        throw new Error(`Unknown command ${command}`);
    }
  }
}
